/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/runs/runs.reducer.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { FileImport, Run } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  allRuns: Run[];
  filteredRuns: Run[];
  loaded: boolean;
  loading: boolean;
  loadingOne: boolean;
  refresh: boolean;
  refreshSelected: boolean;
  selectedRun: Run;
  selectedRunUUID: string;
}

export const initialState: State = {
  allRuns: [],
  filteredRuns: [],
  loaded: false,
  loading: false,
  loadingOne: false,
  refresh: true,
  refreshSelected: true,
  selectedRun: undefined,
  selectedRunUUID: undefined
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.runs.CREATE_RUN_SUCCESS:
      return Object.assign({}, state, {
        allRuns: [action.payload, ...state.allRuns]
      });

    case actions.runs.DELETE_RUN_SUCCESS:
      return Object.assign({}, state, {
        allRuns: state.allRuns.filter((run: Run) => run.uuid !== action.payload.uuid)
      });

    case actions.runs.STORE_FILTERED_RUNS:
      return Object.assign({}, state, {
        filteredRuns: [...action.payload]
      });

    case actions.runs.LINK_FILE_IMPORT_SUCCESS:
    case actions.runs.UNLINK_FILE_IMPORT_SUCCESS:
      if (!state.selectedRun || !state.selectedRun.input_deck) {
        return Object.assign({}, state, {
          loadingOne: false
        });
      }
      return Object.assign({}, state, {
        selectedRun: Object.assign({}, state.selectedRun, {
          input_deck: Object.assign({}, state.selectedRun.input_deck, {
            file_imports: state.selectedRun.input_deck.file_imports.map((fileImport: FileImport) => {
              if (fileImport.uuid === action.payload.uuid) {
                return action.payload;
              }
              return fileImport;
            })
          })
        }),
        loadingOne: false
      });

    case actions.runs.LOAD_ONE_AND_SELECT:
    case actions.runs.LINK_FILE_IMPORT:
    case actions.runs.UNLINK_FILE_IMPORT:
      return Object.assign({}, state, {
        loadingOne: true
      });

    case actions.runs.LOAD_ONE_QUIETLY_SUCCESS:
    case actions.runs.QUEUE_ON_CLUSTER_SUCCESS:
    case actions.runs.RUN_ON_TEST_MACHINE_SUCCESS:
    case actions.runs.STOP_RUN_ON_CLUSTER_SUCCESS:
    case actions.runs.STOP_RUN_ON_TEST_MACHINE_SUCCESS:
    case actions.runs.UPDATE_RUN_SUCCESS:
      return Object.assign({}, state, {
        allRuns: state.allRuns
          .map((run: Run) => run.uuid === action.payload.uuid ? action.payload : run),
        filteredRuns: state.filteredRuns
          .map((run: Run) => run.uuid === action.payload.uuid ? action.payload : run),
        selectedRun: !!state.selectedRun && state.selectedRun.uuid === action.payload.uuid ?
          Object.assign({}, action.payload) :
          state.selectedRun,
      });

    case actions.runs.LOAD_ONE_AND_SELECT_SUCCESS:
      return Object.assign({}, state, {
        loadingOne: false,
        selectedRun: action.payload,
        refresh: true,
        refreshSelected: false
      });

    case actions.runs.LOAD_RUNS_SET_LOADING:
      return Object.assign({}, state, {
        loading: true
      });

    case actions.runs.LOAD_RUNS_SUCCESS:
      return Object.assign({}, state, {
        allRuns: [...action.payload],
        loaded: true,
        loading: false,
        refresh: false
      });

    case actions.runs.INPUT_DECK_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        loadingOne: false,
        selectedRun: Object.assign({}, action.payload)
      });

    case actions.runs.SELECT_RUN:
      return Object.assign({}, state, {
        selectedRunUUID: action.payload ? action.payload.uuid : undefined
      });

    case actions.runs.SELECT_RUN_CLEAR:
      return Object.assign({}, state, {
        selectedRun: undefined,
        refreshSelected: true
      });

    case actions.runs.REFRESH_ONE_RUN:
      return Object.assign({}, state, {
        refreshSelected: true
      });

    case actions.runs.BACKEND_SAYS_REFRESH_ONE_RUN:
    case actions.runs.REFRESH_RUNS:
      return Object.assign({}, state, {
        refresh: true
      });

    case REFRESH_ALL:
      return Object.assign({}, state, {
        refresh: true,
        refreshSelected: true
      });

    case CLEAR_ALL_STORES:
      return Object.assign({}, initialState);

    default: {
      return state;
    }
  }
}

export const getAll = (state: State) => state.allRuns;

export const getFiltered = (state: State) => state.filteredRuns;

export const getRefresh = (state: State) => state.refresh;
export const getRefreshSelected = (state: State) => state.refreshSelected;

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;
export const getLoadingOne = (state: State) => state.loadingOne;

export const getSelected = (state: State) => state.selectedRun;

export const getSelectedUUID = (state: State) => state.selectedRunUUID;
