/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/general-files-properties/general-files-properties.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { GeneralFile } from '../interfaces';

export const DELETE_GENERAL_FILE =          '[General Files Properties] Delete';
export const DELETE_GENERAL_FILE_SUCCESS =  '[General Files Properties] Delete Success';
export const LOAD_GENERAL_FILES =           '[General Files Properties] Load';
export const LOAD_GENERAL_FILES_FAIL =      '[General Files Properties] Load Fail';
export const LOAD_GENERAL_FILES_SET_TAG =   '[General Files Properties] Load Set Loading Tag';
export const LOAD_GENERAL_FILES_SUCCESS =   '[General Files Properties] Load Success';
export const REFRESH_GENERAL_FILES =        '[General Files Properties] Refresh';
export const UPDATE_GENERAL_FILE =          '[General Files Properties] Update';
export const UPDATE_GENERAL_FILE_SUCCESS =  '[General Files Properties] Update Success';

export class DeleteFile implements Action {
  readonly type = DELETE_GENERAL_FILE;
  constructor(public payload: GeneralFile) { }
}

export class DeleteFileSuccess implements Action {
  readonly type = DELETE_GENERAL_FILE_SUCCESS;
  constructor(public payload: GeneralFile) { }
}

export class Load implements Action {
  readonly type = LOAD_GENERAL_FILES;
  constructor(public payload: string) { }
}

export class LoadFail implements Action {
  type = LOAD_GENERAL_FILES_FAIL;
  constructor(public payload: any) { }
}

export class LoadSetTag implements Action {
  readonly type = LOAD_GENERAL_FILES_SET_TAG;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_GENERAL_FILES_SUCCESS;
  constructor(public payload: GeneralFile[]) { }
}

export class Refresh implements Action {
  readonly type = REFRESH_GENERAL_FILES;
}

export class Update implements Action {
  readonly type = UPDATE_GENERAL_FILE;
  constructor(public payload: GeneralFile) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_GENERAL_FILE_SUCCESS;
  constructor(public payload: GeneralFile) { }
}
