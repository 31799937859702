/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/actions/login.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

export const RENEW_TOKEN_FOR_401 = '[Login] Request failed with 401';
export const LOGIN_FAIL =          '[Login] Login Fail';
export const LOGIN_REFRESH =       '[Login] Login Refresh';
export const LOGIN_SUCCESS =       '[Login] Login Success';
export const LOGOUT =              '[Login] Logout';
export const STORE_USERNAME = '[Login] Store username';

export class RenewTokenFor401 implements Action {
  type = RENEW_TOKEN_FOR_401;
  constructor(public payload: Action) { }
}

export class LoginFail implements Action {
  type = LOGIN_FAIL;
  constructor(public payload: string[]) { }
}

export class LoginRefresh implements Action {
  type = LOGIN_REFRESH;
}

export class LoginSuccess implements Action {
  type = LOGIN_SUCCESS;
  constructor(public payload: string) { }
}

export class Logout implements Action {
  type = LOGOUT;
}

export class StoreUsername implements Action {
  type = STORE_USERNAME;
  constructor(public payload: string) { }
}
