/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/account/actions.account.reducer.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import {  Balance, Transaction } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  uuid: string;
  name: string;
  balance: Balance;
  pflotranVersion: string;
  refreshBalance: boolean;
  refreshDetails: boolean;
  refreshTransactions: boolean;
  transactions: Transaction[];
  transactionsLoading: boolean;
}

export const initialState: State = {
  uuid: undefined,
  name: undefined,
  balance: {
    balance: 0,
    reserved: 0
  },
  pflotranVersion: '',
  refreshBalance: true,
  refreshDetails: true,
  refreshTransactions: true,
  transactions: [],
  transactionsLoading: false
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.account.GET_ACCOUNT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        uuid: action.payload.uuid,
        name: action.payload.name,
        balance: action.payload.balance,
        pflotranVersion: action.payload.pflotran_version,
        refreshDetails: false,
      });

    case actions.account.GET_BALANCE_SUCCESS: {
      return Object.assign({}, state, {
        balance: action.payload,
        refreshBalance: false,
      });
    }

    case actions.account.GET_TRANSACTIONS: {
      return Object.assign({}, state, {
        transactionsLoading: true
      });
    }

    case actions.account.GET_TRANSACTIONS_SUCCESS: {
      return Object.assign({}, state, {
        refreshTransactions: false,
        transactions: [...action.payload],
        transactionsLoading: false
      });
    }

    case actions.account.REFRESH_ACCOUNT_BALANCE:
      return Object.assign({}, state, {
        refreshBalance: true
      });

    case actions.account.REFRESH_ACCOUNT_DETAILS:
      return Object.assign({}, state, {
        refreshDetails: true
      });

    case actions.account.REFRESH_ACCOUNT_TRANSACTIONS:
      return Object.assign({}, state, {
        refreshTransactions: true
      });

    case REFRESH_ALL:
      return Object.assign({}, state, {
        refreshBalance: true,
        refreshDetails: true,
        refreshTransactions: true
      });

    case CLEAR_ALL_STORES: {
      return Object.assign({}, initialState);
    }

    default: {
      return state;
    }
  }
}

export const getBalance = (state: State): Balance => state.balance;
export const getBalanceRefresh = (state: State) => state.refreshBalance;

export const getDetailsRefresh = (state: State) => state.refreshDetails;
export const getName = (state: State) => state.name;

export const getPflotranVersion = (state: State) => state.pflotranVersion;

export const getTransactions = (state: State) => state.transactions;
export const getTransactionsLoading = (state: State) => state.transactionsLoading;
export const getTransactionsRefresh = (state: State) => state.refreshTransactions;
