/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/1d-plots/1d-plots.reducer.ts
 * Copyright 2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { ExternalDataFile, OneDPlotConfig } from '../interfaces';
import { CLEAR_ALL_STORES } from '../app.store.constants';

export interface State {
  config: OneDPlotConfig;
  externalDataFiles: ExternalDataFile[];
  refreshExternalDataFiles: boolean;
}

export const initialState: State = {
  config: {plots: [], compare: []},
  externalDataFiles: [],
  refreshExternalDataFiles: true
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.oneDPlots.REFRESH_EXTERNAL_DATA_FILES:
    case actions.subProjects.STORE_SELECTED_SUBPROJECT:
      return Object.assign({}, state, {
        refreshExternalDataFiles: true
      });

    case actions.oneDPlots.LOAD_EXTERNAL_DATA_FILES_SUCCESS:
      return Object.assign({}, state, {
        externalDataFiles: action.payload,
        refreshExternalDataFiles: false
      });

    case actions.oneDPlots.STORE_PLOTS_CONFIG:
      return Object.assign({}, state, {
        config: action.payload
      });

    case CLEAR_ALL_STORES: {
      return Object.assign({}, initialState);
    }

    default: {
      return state;
    }
  }
}

export const getConfig = (state: State) => state.config;
export const getExternalDataFiles = (state: State) => state.externalDataFiles;
export const getExternalDataFilesRefresh = (state: State) => state.refreshExternalDataFiles;
