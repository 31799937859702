/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/sub-projects/sub-projects.reducers.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { SubProject } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  subProjects: SubProject[];
  filtered: SubProject[];
  loaded: boolean;
  loading: boolean;
  refresh: boolean;
  selectedSubProject: SubProject;
  selectedUUID: string;
}

export const initialState: State = {
  subProjects: [],
  filtered: [],
  loaded: false,
  loading: false,
  refresh: true,
  selectedSubProject: undefined,
  selectedUUID: undefined
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.subProjects.CREATE_SUBPROJECT_SUCCESS: {
      return Object.assign({}, state, {
        subProjects: [...state.subProjects, action.payload]
      });
    }

    case actions.subProjects.DELETE_SUBPROJECT_SUCCESS: {
      return Object.assign({}, state, {
        subProjects: state.subProjects.filter((subProj: SubProject) => subProj.uuid !== action.payload.uuid)
      });
    }

    case actions.subProjects.LOAD_SUBPROJECTS_SET_LOADING: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case actions.subProjects.LOAD_SUBPROJECTS_SUCCESS: {
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        refresh: false,
        subProjects: [...action.payload]
      });
    }

    case actions.subProjects.SELECT_SUBPROJECT: {
      return Object.assign({}, state, {
        selectedUUID: action.payload ? action.payload.uuid : undefined
      });
    }

    case actions.subProjects.STORE_FILTERED_SUBPROJECTS:
      return Object.assign({}, state, {
        filtered: action.payload
      });

    case actions.subProjects.STORE_SELECTED_SUBPROJECT: {
      return Object.assign({}, state, {
        selectedSubProject: action.payload
      });
    }

    case actions.subProjects.UPDATE_SUBPROJECT_SUCCESS: {
      return Object.assign({}, state, {
        subProjects: state.subProjects
          .map((subProj: SubProject) => subProj.uuid === action.payload.uuid ? action.payload : subProj)
      });
    }

    case actions.subProjects.REFRESH_SUBPROJECTS:
    case REFRESH_ALL:
      return Object.assign({}, state, {
        refresh: true
      });

    case CLEAR_ALL_STORES:
      return Object.assign({}, initialState);

    default: {
      return state;
    }
  }
}

export const getAllSubProjects = (state: State) => state.subProjects;

export const getFilteredSubProjects = (state: State) => state.filtered;

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getRefresh = (state: State) => state.refresh;

export const getSelectedSubProject = (state: State) => state.selectedSubProject;

export const getSelectedSubProjectUUID = (state: State) => state.selectedUUID;
