/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/actions/queue.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { QueueItem, TestMachine } from '../interfaces';

export const GET_QUEUE =                      '[Compute Resources] Get Queue';
export const GET_QUEUE_SUCCESS =              '[Compute Resources] Get Queue Success';
export const GET_TEST_MACHINE_STATE =         '[Compute Resources] Get Test Machine State';
export const GET_TEST_MACHINE_STATE_FAIL =    '[Compute Resources] Get Test Machine State Fail';
export const GET_TEST_MACHINE_STATE_SUCCESS = '[Compute Resources] Get Test Machine State Success';
export const REFRESH_QUEUE =                  '[Compute Resources] Refresh Queue';
export const REFRESH_TEST_MACHINE =           '[Compute Resources] Refresh Test Machine';
export const START_TEST_MACHINE =             '[Compute Resources] Start Test Machine';
export const TERMINATE_TEST_MACHINE =         '[Compute Resources] Terminate Test Machine';

export class GetQueue implements Action {
  readonly type = GET_QUEUE;
}

export class GetQueueSuccess implements Action {
  readonly type = GET_QUEUE_SUCCESS;
  constructor(public payload: QueueItem[]) { }
}

export class GetTestMachineState implements Action {
  readonly type = GET_TEST_MACHINE_STATE;
}

export class GetTestMachineStateFail implements Action {
  type = GET_TEST_MACHINE_STATE_FAIL;
  constructor(public payload: any) { }
}

export class GetTestMachineStateSuccess implements Action {
  readonly type = GET_TEST_MACHINE_STATE_SUCCESS;
  constructor(public payload: TestMachine) { }
}

export class RefreshQueue implements Action {
  readonly type = REFRESH_QUEUE;
}

export class RefreshTestMachine implements Action {
  readonly type = REFRESH_TEST_MACHINE;
}

export class StartTestMachine implements Action {
  readonly type = START_TEST_MACHINE;
  constructor(public payload: {size: string, duration: number}) { }
}

export class TerminateTestMachine implements Action {
  readonly type = TERMINATE_TEST_MACHINE;
}
