/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/1d-plots/1d-plots.actions.ts
 * Copyright 2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { ExternalDataFile, OneDPlotConfig, Plot } from '../interfaces';

export const LOAD_EXTERNAL_DATA_FILES = '[Plots] Load External Data Files';
export const LOAD_EXTERNAL_DATA_FILES_FAIL = '[Plots] Load External Data Files Fail';
export const LOAD_EXTERNAL_DATA_FILES_SUCCESS = '[Plots] Load External Data Files Success';
export const PLOT_ADD = '[Plots] Add plot';
export const PLOT_EDIT = '[Plots] Edit plot';
export const PLOT_REMOVE = '[Plots] Remove plot';
export const REFRESH_EXTERNAL_DATA_FILES = '[Plots] Refresh External Data Files';
export const SAVE_COMPARE_RUNS = '[Plots] Save Compare Runs';
export const SAVE_PLOTS = '[Plots] Save';
export const STORE_PLOTS_CONFIG = '[Plots] Store Config';

export class AddPlot implements Action {
  readonly type = PLOT_ADD;
  constructor(public payload: Plot) { }
}

export class EditPlot implements Action {
  readonly type = PLOT_EDIT;
  constructor(public payload: Plot) { }
}

export class LoadExternalDataFiles implements Action {
  readonly type = LOAD_EXTERNAL_DATA_FILES;
  constructor(public payload: string) { }
}

export class LoadExternalDataFilesFail implements Action {
  readonly type = LOAD_EXTERNAL_DATA_FILES_FAIL;
  constructor(public payload: any) {}
}

export class LoadExternalDataFilesSuccess implements Action {
  readonly type = LOAD_EXTERNAL_DATA_FILES_SUCCESS;
  constructor(public payload: ExternalDataFile[]) { }
}

export class RefreshExternalDataFiles implements Action {
  readonly type = REFRESH_EXTERNAL_DATA_FILES;
}

export class RemovePlot implements Action {
  readonly type = PLOT_REMOVE;
  constructor(public payload: Plot) { }
}

export class SaveCompareRuns implements Action {
  readonly type = SAVE_COMPARE_RUNS;
  constructor(public payload: string[]) { }
}

export class SavePlots implements Action {
  readonly type = SAVE_PLOTS;
  constructor(public payload: Plot[]) { }
}

export class StorePlotsConfig implements Action {
  readonly type = STORE_PLOTS_CONFIG;
  constructor(public payload: OneDPlotConfig) { }
}
