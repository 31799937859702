/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/login/login.reducers.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  errors: string[];
  token: string;
  username: string;
}

export const initialState: State = {
  errors: [],
  token: undefined,
  username: undefined
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.login.STORE_USERNAME:
      return Object.assign({}, state, {
        username: action.payload,
        errors: [],
      });

    case actions.login.LOGIN_FAIL:
      localStorage.removeItem('jwt-token');
      const errors: string[] = !!action.payload ?
        action.payload.map((error: string) => {
          if (error === 'Signature has expired.') {
            return 'Login expired.';
          }
          return error;
        }) :
        'Please login again.';
      return Object.assign({}, state, {
        errors: errors,
        errorsSeen: false,
        token: undefined,
        username: undefined
      });

    case actions.login.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        errors: [],
        token: action.payload
      });

    case actions.login.LOGOUT:
    case CLEAR_ALL_STORES:
      localStorage.removeItem('jwt-token');
      return Object.assign({}, initialState);

    default:
      return state;
  }
}

export const getErrors = (state: State) => state.errors;

export const getToken = (state: State) => state.token;

export const getUsername = (state: State) => state.username;
