/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/grids/grids.reducer.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { Grid } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  files: Grid[];
  loaded: boolean;
  loading: boolean;
  refresh: boolean;
}

export const initialState: State = {
  files: [],
  loaded: false,
  loading: false,
  refresh: true,
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.grids.DELETE_GRID_FILE_SUCCESS: {
      return Object.assign({}, state, {
        files: state.files.filter((grid: Grid) => grid.uuid !== action.payload.uuid)
      });
    }

    case actions.grids.LOAD_GRID_FILES_SET_TAG: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case actions.grids.LOAD_GRID_FILES_SUCCESS: {
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        refresh: false,
        files: [...action.payload]
      });
    }

    case actions.grids.UPDATE_GRID_FILE_SUCCESS: {
      return Object.assign({}, state, {
        files: state.files
          .map((grid: Grid) => grid.uuid === action.payload.uuid ? action.payload : grid)
      });
    }

    case actions.grids.REFRESH_GRID_FILES:
    case REFRESH_ALL:
      return Object.assign({}, state, {
        refresh: true
      });

    case CLEAR_ALL_STORES: {
      return Object.assign({}, initialState);
    }

    default: {
      return state;
    }
  }
}

export const getAllGrids = (state: State) => state.files;

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getRefresh = (state: State) => state.refresh;
