/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/general-files-properties/general-files-properties.reducer.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { GeneralFile } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  files: GeneralFile[];
  loaded: boolean;
  loading: boolean;
  refresh: boolean;
}

export const initialState: State = {
  files: [],
  loaded: false,
  loading: false,
  refresh: true,
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.generalFilesProps.DELETE_GENERAL_FILE_SUCCESS: {
      return Object.assign({}, state, {
        files: state.files
          .filter((generalFilesProperty: GeneralFile) => generalFilesProperty.uuid !== action.payload.uuid)
      });
    }

    case actions.generalFilesProps.LOAD_GENERAL_FILES_SET_TAG: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case actions.generalFilesProps.LOAD_GENERAL_FILES_SUCCESS: {
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        refresh: false,
        files: [...action.payload]
      });
    }

    case actions.generalFilesProps.UPDATE_GENERAL_FILE_SUCCESS: {
      return Object.assign({}, state, {
        files: state.files
          .map((generalFilesProperty: GeneralFile) =>
            generalFilesProperty.uuid === action.payload.uuid ? action.payload : generalFilesProperty)
      });
    }

    case REFRESH_ALL:
    case actions.generalFilesProps.REFRESH_GENERAL_FILES:
      return Object.assign({}, state, {
        refresh: true
      });

    case CLEAR_ALL_STORES: {
      return Object.assign({}, initialState);
    }

    default: {
      return state;
    }
  }
}

export const getGeneralFiles = (state: State) => state.files;

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getRefresh = (state: State) => state.refresh;
