/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/grids/grids.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { Grid } from '../interfaces';

export const DELETE_GRID_FILE =          '[Grids] Delete';
export const DELETE_GRID_FILE_SUCCESS =  '[Grids] Delete Success';
export const LOAD_GRID_FILES =           '[Grids] Load';
export const LOAD_GRID_FILES_FAIL =      '[Grids] Load Fail';
export const LOAD_GRID_FILES_SET_TAG =   '[Grids] Load Set Loading Tag';
export const LOAD_GRID_FILES_SUCCESS =   '[Grids] Load Success';
export const REFRESH_GRID_FILES =        '[Grids] Refresh';
export const UPDATE_GRID_FILE =          '[Grids] Update';
export const UPDATE_GRID_FILE_SUCCESS =  '[Grids] Update Success';

export class DeleteFile implements Action {
  readonly type = DELETE_GRID_FILE;
  constructor(public payload: Grid) { }
}

export class DeleteFileSuccess implements Action {
  readonly type = DELETE_GRID_FILE_SUCCESS;
  constructor(public payload: Grid) { }
}

export class Load implements Action {
  readonly type = LOAD_GRID_FILES;
  constructor(public payload: string) { }
}

export class LoadFail implements Action {
  type = LOAD_GRID_FILES_FAIL;
  constructor(public payload: any) { }
}

export class LoadSetTag implements Action {
  readonly type = LOAD_GRID_FILES_SET_TAG;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_GRID_FILES_SUCCESS;
  constructor(public payload: Grid[]) { }
}

export class Refresh implements Action {
  readonly type = REFRESH_GRID_FILES;
}

export class Update implements Action {
  readonly type = UPDATE_GRID_FILE;
  constructor(public payload: Grid) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_GRID_FILE_SUCCESS;
  constructor(public payload: Grid) { }
}
