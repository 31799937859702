/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/projects/projects.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { Project } from '../interfaces';

export const CREATE_PROJECT =            '[Projects] Create';
export const CREATE_PROJECT_SUCCESS =    '[Projects] Create Success';
export const DELETE_PROJECT =            '[Projects] Delete';
export const DELETE_PROJECT_SUCCESS =    '[Projects] Delete Success';
export const LOAD_PROJECTS =             '[Projects] Load';
export const LOAD_PROJECTS_FAIL =        '[Projects] Load Fail';
export const LOAD_PROJECTS_SET_LOADING = '[Projects] Load Set Loading';
export const LOAD_PROJECTS_SUCCESS =     '[Projects] Load Success';
export const REFRESH_PROJECTS =          '[Projects] Refresh';
export const SELECT_PROJECT =            '[Projects] Select';
export const STORE_SELECTED_PROJECT =    '[Projects] Store Selected Project';
export const UPDATE_PROJECT =            '[Projects] Update';
export const UPDATE_PROJECT_SUCCESS =    '[Projects] Update Success';

export class Create implements Action {
  readonly type = CREATE_PROJECT;
  constructor(public payload: Project) { }
}

export class CreateSuccess implements Action {
  readonly type = CREATE_PROJECT_SUCCESS;
  constructor(public payload: Project) { }
}

export class Delete implements Action {
  readonly type = DELETE_PROJECT;
  constructor(public payload: Project) { }
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_PROJECT_SUCCESS;
  constructor(public payload: Project) { }
}

export class Load implements Action {
  readonly type = LOAD_PROJECTS;
}

export class LoadFail implements Action {
  readonly type = LOAD_PROJECTS_FAIL;
  constructor(public payload: any) { }
}

export class LoadStarted implements Action {
  readonly type = LOAD_PROJECTS_SET_LOADING;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_PROJECTS_SUCCESS;
  constructor(public payload: Project[]) { }
}

export class Refresh implements Action {
  readonly type = REFRESH_PROJECTS;
}

export class Select implements Action {
  readonly type = SELECT_PROJECT;
  constructor(public payload: Project) { }
}

export class StoreSelected implements Action {
  readonly type = STORE_SELECTED_PROJECT;
  constructor(public payload: Project) { }
}

export class Update implements Action {
  readonly type = UPDATE_PROJECT;
  constructor(public payload: Project) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_PROJECT_SUCCESS;
  constructor(public payload: Project) { }
}
