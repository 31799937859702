/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/material-properties/material-properties.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { MaterialProperty } from '../interfaces';

export const DELETE_MATERIAL_FILE =          '[Material Properties] Delete';
export const DELETE_MATERIAL_FILE_SUCCESS =  '[Material Properties] Delete Success';
export const LOAD_MATERIAL_FILES =           '[Material Properties] Load';
export const LOAD_MATERIAL_FILES_FAIL =      '[Material Properties] Load Fail';
export const LOAD_MATERIAL_FILES_SET_TAG =   '[Material Properties] Load Set Loading Tag';
export const LOAD_MATERIAL_FILES_SUCCESS =   '[Material Properties] Load Success';
export const REFRESH_MATERIAL_FILES =        '[Material Properties] Refresh';
export const UPDATE_MATERIAL_FILE =          '[Material Properties] Update';
export const UPDATE_MATERIAL_FILE_SUCCESS =  '[Material Properties] Update Success';

export class DeleteFile implements Action {
  readonly type = DELETE_MATERIAL_FILE;
  constructor(public payload: MaterialProperty) { }
}

export class DeleteFileSuccess implements Action {
  readonly type = DELETE_MATERIAL_FILE_SUCCESS;
  constructor(public payload: MaterialProperty) { }
}

export class Load implements Action {
  readonly type = LOAD_MATERIAL_FILES;
  constructor(public payload: string) { }
}

export class LoadFail implements Action {
  type = LOAD_MATERIAL_FILES_FAIL;
  constructor(public payload: any) { }
}

export class LoadSetTag implements Action {
  readonly type = LOAD_MATERIAL_FILES_SET_TAG;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_MATERIAL_FILES_SUCCESS;
  constructor(public payload: MaterialProperty[]) { }
}

export class Refresh implements Action {
  readonly type = REFRESH_MATERIAL_FILES;
}

export class Update implements Action {
  readonly type = UPDATE_MATERIAL_FILE;
  constructor(public payload: MaterialProperty) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_MATERIAL_FILE_SUCCESS;
  constructor(public payload: MaterialProperty) { }
}
