/* ==================================================================================================================
 * OpenGoSim Bluebell: app/app-utils.ts
 * Copyright 2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
// Return a random value from an array of values
const randomFromArray = (array: any[]) => array[Math.floor(Math.random() * array.length)];

// Return a random hexadecimal number, as a string
const randomHex = (): string => Math.floor(Math.random() * 16).toString(16);

// Returns a `length`-long string of hexadecimal digits
const hexString = (length: number): string => Array.from({length}, randomHex).join('');

// This function creates a pseudo-UUID
// It was created to give an ID to plots
// UUIDs were used instead of simple IDs as there may be a need to search for them in the database
export const uuid4 = (): string => [
  hexString(8),
  hexString(4),
  '4' + hexString(3),
  randomFromArray(['8', '9', 'a', 'b']) + hexString(3),
  hexString(12)
].join('-');

// Helper function to detect whether two arrays are objectively identical,
// even if, technically, Javascript considers them to be different objects.
export const arraysEqual = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.map((element: any, index: number) => element === arr2[index]).indexOf(false) === -1;
};

// Helper function to detect whether two objects are objectively identical, even if they are not according
// to the technicalities of Computer Science.
// To be the same, they must have exactly the same keys, and the values must pass the `===` condition
// Because only `===` is used, this is really only useful for objects containing strings, numbers, boolean values.
export const twoObjectsSame = (obj1: any, obj2: any): boolean => {
  if (!arraysEqual(Object.keys(obj1), Object.keys(obj2))) {
    return false;
  }
  return Object.keys(obj1).map((key: string) => obj1[key] === obj2[key]).indexOf(false) === -1;
};

const ASCII_FILE_EXTENSIONS: string[] = [
  'in', 'grdecl', 'inc', 'include', 'dat', 'data', 'txt', 'uge', 'ugi', 'ex', 'ss', 'xmf', 'out', 'log', 'logfile', 'tec', 'fgrid',
  'fsmspec', 'finit', 'funrst', 'funsmry'
];

export const hasASCIIFileExtension = (filename): boolean => {
  // N.B. This also tidies up duplicate files like 'foo.txt (3)'
  const ext = /(\w+)(?: \(\d+\))?/.exec(filename.substr(filename.lastIndexOf('.') + 1))[1];
  return ASCII_FILE_EXTENSIONS.includes(ext);
};

export const isSmall = (file): boolean => {
    const ext = file.size <= 1000000;
    return ext;
};
