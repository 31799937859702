/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/reducers/queue.reducer.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { QueueItem, TestMachine } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  refreshQueue: boolean;
  refreshTestMachine: boolean;
  queue: QueueItem[];
  queueLoading: boolean;
  testMachine: TestMachine;
}

export const initialState: State = {
  refreshQueue: true,
  refreshTestMachine: true,
  queue: [],
  queueLoading: false,
  testMachine: {instanceType: undefined, status: 'stpd'}
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.computeResources.GET_TEST_MACHINE_STATE_SUCCESS: {
      return Object.assign({}, state, {
        refreshTestMachine: false,
        testMachine: Object.assign({}, {
          cores: action.payload.cores,
          status: action.payload.status,
          instanceType: action.payload.instanceType
        })
      });
    }

    case actions.computeResources.GET_QUEUE: {
      return Object.assign({}, state, {
        queueLoading: true
      });
    }

    case actions.computeResources.GET_QUEUE_SUCCESS: {
      return Object.assign({}, state, {
        refreshQueue: false,
        queue: [...action.payload],
        queueLoading: false
      });
    }

    case actions.computeResources.REFRESH_QUEUE:
      return Object.assign({}, state, {
        refreshQueue: true
      });

    case actions.computeResources.REFRESH_TEST_MACHINE:
      return Object.assign({}, state, {
        refreshTestMachine: true
      });

    case REFRESH_ALL:
      return Object.assign({} , state, {
        refreshQueue: true,
        refreshTestMachine: true
      });

    case CLEAR_ALL_STORES: {
      return Object.assign({}, initialState);
    }

    default: {
      return state;
    }
  }
}

export const getQueue = (state: State) => state.queue;

export const getQueueLoading = (state: State) => state.queueLoading;

export const getQueueRefresh = (state: State) => state.refreshQueue;

export const getTestMachineRefresh = (state: State) => state.refreshTestMachine;

export const getTestMachineState = (state: State) => state.testMachine;
