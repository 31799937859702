/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/fluid-properties/fluid-properties.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { FluidProperty } from '../interfaces';

export const DELETE_FLUID_FILE =          '[Fluid Properties] Delete';
export const DELETE_FLUID_FILE_SUCCESS =  '[Fluid Properties] Delete Success';
export const LOAD_FLUID_FILES =           '[Fluid Properties] Load';
export const LOAD_FLUID_FILES_FAIL =      '[Fluid Properties] Load Fail';
export const LOAD_FLUID_FILES_SET_TAG =   '[Fluid Properties] Load Set Loading Tag';
export const LOAD_FLUID_FILES_SUCCESS =   '[Fluid Properties] Load Success';
export const REFRESH_FLUID_FILES =        '[Fluid Properties] Refresh';
export const UPDATE_FLUID_FILE =          '[Fluid Properties] Update';
export const UPDATE_FLUID_FILE_SUCCESS =  '[Fluid Properties] Update Success';

export class DeleteFile implements Action {
  readonly type = DELETE_FLUID_FILE;
  constructor(public payload: FluidProperty) { }
}

export class DeleteFileSuccess implements Action {
  readonly type = DELETE_FLUID_FILE_SUCCESS;
  constructor(public payload: FluidProperty) { }
}

export class Load implements Action {
  readonly type = LOAD_FLUID_FILES;
  constructor(public payload: string) { }
}

export class LoadFail implements Action {
  type = LOAD_FLUID_FILES_FAIL;
  constructor(public payload: any) { }
}

export class LoadSetTag implements Action {
  readonly type = LOAD_FLUID_FILES_SET_TAG;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_FLUID_FILES_SUCCESS;
  constructor(public payload: FluidProperty[]) { }
}

export class Refresh implements Action {
  readonly type = REFRESH_FLUID_FILES;
}

export class Update implements Action {
  readonly type = UPDATE_FLUID_FILE;
  constructor(public payload: FluidProperty) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_FLUID_FILE_SUCCESS;
  constructor(public payload: FluidProperty) { }
}
