/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/sub-projects/sub-sub-projects.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { SubProject } from '../interfaces';

export const CREATE_SUBPROJECT =            '[Sub-Projects] Create';
export const CREATE_SUBPROJECT_SUCCESS =    '[Sub-Projects] Create Success';
export const DELETE_SUBPROJECT =            '[Sub-Projects] Delete';
export const DELETE_SUBPROJECT_SUCCESS =    '[Sub-Projects] Delete Success';
export const LOAD_SUBPROJECTS =             '[Sub-Projects] Load';
export const LOAD_SUBPROJECTS_FAIL =        '[Sub-Projects] Load Fail';
export const LOAD_SUBPROJECTS_SET_LOADING = '[Sub-Projects] Load Set Loading';
export const LOAD_SUBPROJECTS_SUCCESS =     '[Sub-Projects] Load Success';
export const REFRESH_SUBPROJECTS =          '[Sub-Projects] Refresh';
export const SELECT_SUBPROJECT =            '[Sub-Projects] Select';
export const STORE_FILTERED_SUBPROJECTS =   '[Sub-Projects] Store Filtered Sub-Projects';
export const STORE_SELECTED_SUBPROJECT =    '[Sub-Projects] Stored Selected';
export const UPDATE_SUBPROJECT =            '[Sub-Projects] Update';
export const UPDATE_SUBPROJECT_SUCCESS =    '[Sub-Projects] Update Success';

export class Create implements Action {
  readonly type = CREATE_SUBPROJECT;
  constructor(public payload: SubProject) { }
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUBPROJECT_SUCCESS;
  constructor(public payload: SubProject) { }
}

export class Delete implements Action {
  readonly type = DELETE_SUBPROJECT;
  constructor(public payload: SubProject) { }
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUBPROJECT_SUCCESS;
  constructor(public payload: SubProject) { }
}

export class Load implements Action {
  readonly type = LOAD_SUBPROJECTS;
}

export class LoadFail implements Action {
  readonly type = LOAD_SUBPROJECTS_FAIL;
  constructor(public payload: any) { }
}

export class LoadStarted implements Action {
  readonly type = LOAD_SUBPROJECTS_SET_LOADING;
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUBPROJECTS_SUCCESS;
  constructor(public payload: SubProject[]) { }
}

export class Refresh implements Action {
  readonly type = REFRESH_SUBPROJECTS;
}

export class Select implements Action {
  readonly type = SELECT_SUBPROJECT;
  constructor(public payload: SubProject) { }
}

export class StoreFiltered implements Action {
  readonly type = STORE_FILTERED_SUBPROJECTS;
  constructor(public payload: SubProject[]) { }
}

export class StoreSelected implements Action {
  readonly type = STORE_SELECTED_SUBPROJECT;
  constructor(public payload: SubProject) { }
}

export class Update implements Action {
  readonly type = UPDATE_SUBPROJECT;

  constructor(public payload: SubProject) { }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUBPROJECT_SUCCESS;
  constructor(public payload: SubProject) { }
}
