/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/account/account.actions.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Action } from '@ngrx/store';

import { AppAccount, Balance, Transaction } from '../interfaces';

export const GET_ACCOUNT_DETAILS =          '[Account] Get';
export const GET_ACCOUNT_DETAILS_FAIL =     '[Account] Get Fail';
export const GET_ACCOUNT_DETAILS_SUCCESS =  '[Account] Get Success';
export const GET_BALANCE =                  '[Account] Get Balance';
export const GET_BALANCE_SUCCESS =          '[Account] Get Balance Success';
export const GET_TRANSACTIONS =             '[Account] Get Transactions';
export const GET_TRANSACTIONS_SUCCESS =     '[Account] Get Transactions Success';
export const REFRESH_ACCOUNT_BALANCE =      '[Account] Refresh Balance';
export const REFRESH_ACCOUNT_DETAILS =      '[Account] Refresh Details';
export const REFRESH_ACCOUNT_TRANSACTIONS = '[Account] Refresh Transactions';

export class GetAccount implements Action {
  readonly type = GET_ACCOUNT_DETAILS;
}

export class GetAccountFail implements Action {
  readonly type = GET_ACCOUNT_DETAILS_FAIL;
  constructor(public payload: any) { }
}

export class GetAccountSuccess implements Action {
  readonly type = GET_ACCOUNT_DETAILS_SUCCESS;
  constructor(public payload: AppAccount) { }
}

export class GetBalance implements Action {
  readonly type = GET_BALANCE;
}

export class GetBalanceSuccess implements Action {
  readonly type = GET_BALANCE_SUCCESS;
  constructor(public payload: Balance) { }
}

export class GetTransactions implements Action {
  readonly type = GET_TRANSACTIONS;
}

export class GetTransactionsSuccess implements Action {
  readonly type = GET_TRANSACTIONS_SUCCESS;
  constructor(public payload: Transaction[]) { }
}

export class RefreshBalance implements Action {
  readonly type = REFRESH_ACCOUNT_BALANCE;
}

export class RefreshDetails implements Action {
  readonly type = REFRESH_ACCOUNT_DETAILS;
}

export class RefreshTransactions implements Action {
  readonly type = REFRESH_ACCOUNT_TRANSACTIONS;
}
