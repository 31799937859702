/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/app.store.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { ActionReducer, Store, select } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, map, share, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { storeLogger } from '@zerops/ngrx-store-logger';
import { arraysEqual, twoObjectsSame } from 'app/app-utils';
import * as actions from './actions';  // PyCharm sometimes throws a default export error here. Compiles ok.

import {
  Balance,
  ExternalDataFile,
  FluidProperty,
  GeneralFile,
  Grid,
  MaterialProperty,
  OneDPlotConfig,
  Plot,
  Project,
  QueueItem,
  Run,
  SubProject,
  TestMachine,
  Transaction
} from './interfaces';
import * as reducers from './reducers';

export interface State {
  account: reducers.account.State;
  computeResources: reducers.computeResources.State;
  fluidProperties: reducers.fluidProps.State;
  generalFilesProperties: reducers.generalFilesProps.State;
  grids: reducers.grids.State;
  login: reducers.login.State;
  materialProperties: reducers.materialProps.State;
  plots1D: reducers.oneDPlots.State;
  projects: reducers.projects.State;
  subProjects: reducers.subProjects.State;
  runs: reducers.runs.State;
}

export function logger(reducer: ActionReducer<State>): any {
  return storeLogger({collapsed: true})(reducer);
}

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {login: ['username', 'token']}
    ],
    rehydrate: true
  })(reducer);
}

export const metaReducers = [logger, localStorageReducer];

export function getInitialState() {
  return {
    account: reducers.account.initialState,
    computeResources: reducers.computeResources.initialState,
    fluidProperties: reducers.fluidProps.initialState,
    generalFilesProperties: reducers.generalFilesProps.initialState,
    grids: reducers.grids.initialState,
    login: reducers.login.initialState,
    materialProperties: reducers.materialProps.initialState,
    plots1D: reducers.oneDPlots.initialState,
    projects: reducers.projects.initialState,
    subProjects: reducers.subProjects.initialState,
    runs: reducers.runs.initialState
  };
}


@Injectable({
  providedIn: 'root',
})
export class AppStoreUtils {

  // account
  accountBalance: Observable<Balance>;
  accountName: Observable<string>;
  accountTransactions: Observable<Transaction[]>;
  accountTransactionsLoading: Observable<boolean>;
  pflotranVersion: Observable<string>;

  // compute resources
  queue: Observable<QueueItem[]>;
  queueLoading: Observable<boolean>;
  testMachineState: Observable<TestMachine>;

  // fluid properties files
  fluidProps: Observable<FluidProperty[]>;
  fluidPropsLoaded: Observable<boolean>;
  fluidPropsLoading: Observable<boolean>;

  // general files properties files
  generalFilesProps: Observable<GeneralFile[]>;
  generalFilesPropsLoaded: Observable<boolean>;
  generalFilesPropsLoading: Observable<boolean>;

  // grid files
  grids: Observable<Grid[]>;
  gridsLoaded: Observable<boolean>;
  gridsLoading: Observable<boolean>;

  // loginActions
  loginErrors: Observable<string[]>;
  loginToken: Observable<string>;
  loginUsername: Observable<string>;

  // material properties files
  materialProps: Observable<MaterialProperty[]>;
  materialPropsLoaded: Observable<boolean>;
  materialPropsLoading: Observable<boolean>;

  // projects
  projects: Observable<Project[]>;
  selectedProject: Observable<Project>;
  selectedProjectUUID: Observable<string>;
  projectsLoaded: Observable<boolean>;
  projectsLoading: Observable<boolean>;

  // plots 1D
  plots1D: Observable<Plot[]>;
  plots1DCompareRuns: Observable<string[]>;
  plots1DConfig: Observable<OneDPlotConfig>;
  plots1DExternalDataFiles: Observable<ExternalDataFile[]>;

  // sub-projects
  allSubProjects: Observable<SubProject[]>;
  filteredSubProjects: Observable<SubProject[]>;
  selectedSubProject: Observable<SubProject>;
  selectedSubProjectUUID: Observable<string>;
  subProjectsLoaded: Observable<boolean>;
  subProjectsLoading: Observable<boolean>;

  // runs
  allRuns: Observable<Run[]>;
  filteredRuns: Observable<Run[]>;
  runsLoaded: Observable<boolean>;
  runsLoading: Observable<boolean>;
  runsLoadingOne: Observable<boolean>;
  selectedRun: Observable<Run>;

  _account = (state: State) => state.account;
  _compute = (state: State) => state.computeResources;
  _fluidProperties = (state: State) => state.fluidProperties;
  _generalFilesProperties = (state: State) => state.generalFilesProperties;
  _grids = (state: State) => state.grids;
  _login = (state: State) => state.login;
  _materialProperties = (state: State) => state.materialProperties;
  _plots = (state: State) => state.plots1D;
  _projects = (state: State) => state.projects;
  _subProjects = (state: State) => state.subProjects;
  _runs = (state: State) => state.runs;

  constructor(private store: Store<State>) {
    this.accountTransactionsLoading = store.pipe(select(this._account), select(reducers.account.getTransactionsLoading));

    this.fluidPropsLoaded = store.pipe(select(this._fluidProperties), select(reducers.fluidProps.getLoaded));
    this.fluidPropsLoading = store.pipe(select(this._fluidProperties), select(reducers.fluidProps.getLoading));

    this.generalFilesPropsLoaded = store.pipe(select(this._generalFilesProperties), select(reducers.generalFilesProps.getLoaded));
    this.generalFilesPropsLoading = store.pipe(select(this._generalFilesProperties), select(reducers.generalFilesProps.getLoading));

    this.gridsLoaded = store.pipe(select(this._grids), select(reducers.grids.getLoaded));
    this.gridsLoading = store.pipe(select(this._grids), select(reducers.grids.getLoading));

    this.loginErrors = store.pipe(select(this._login), select(reducers.login.getErrors));
    this.loginToken = store.pipe(select(this._login), select(reducers.login.getToken));
    this.loginUsername = store.pipe(select(this._login), select(reducers.login.getUsername));

    this.materialPropsLoaded = store.pipe(select(this._materialProperties), select(reducers.materialProps.getLoaded));
    this.materialPropsLoading = store.pipe(select(this._materialProperties), select(reducers.materialProps.getLoading));

    this.selectedProjectUUID = store.pipe(select(this._projects), select(reducers.projects.getSelectedProjectUUID));
    this.projectsLoaded = store.pipe(select(this._projects), select(reducers.projects.getLoaded));
    this.projectsLoading = store.pipe(select(this._projects), select(reducers.projects.getLoading));

    this.selectedSubProjectUUID = store.pipe(select(this._subProjects), select(reducers.subProjects.getSelectedSubProjectUUID));
    this.subProjectsLoaded = store.pipe(select(this._subProjects), select(reducers.subProjects.getLoaded));
    this.subProjectsLoading = store.pipe(select(this._subProjects), select(reducers.subProjects.getLoading));

    this.queueLoading = store.pipe(select(this._compute), select(reducers.computeResources.getQueueLoading));

    this.runsLoaded = store.pipe(select(this._runs), select(reducers.runs.getLoaded));
    this.runsLoading = store.pipe(select(this._runs), select(reducers.runs.getLoading));
    this.runsLoadingOne = store.pipe(select(this._runs), select(reducers.runs.getLoadingOne));

    // muteFirst combines two observables but subscriptions only get the second
    // This is useful if you wish the first observable to be subscribed to but not not actually used directly
    // We use it here to trigger backend calls when a component subscribes to something in the store, like projects
    const muteFirst = <T, R>(first$: Observable<T>, second$: Observable<R>) =>
      second$.pipe(withLatestFrom(first$, (s, _) => s));

    // Below are various "interest-triggered backend calls"
    // See the file in Dropbox/Bramble/OpenGoSim/Dev Notes/
    // Basic concept is that components subscribe to the store but also the observable that triggers the API call
    // They come in the form
    //
    // subscribeToThis = muteFirst(
    //     store.pipe(
    //         select(refreshBoolean),                   this dictates when to do backend call
    //         filter(bool => bool),                     continue only if refresh is true
    //         tap(() => dispatch the API call effect),  this is still done in side effect as easier for errors/retries
    //                                                   it also sets refresh = false when data is stored in store
    //         share(),
    //         startWith()
    //    ),
    //    store.pipe(
    //        select(theStoredVariableThatWeWant)
    //    );
    //
    // Subscribing to the variable means that the backend call will be made whenever refresh is true.
    // If no component is subscribed to it, then no calls are made, even if refresh is true.

    //
    // Account
    //
    this.accountBalance = muteFirst(
      store.pipe(
        select(this._account),
        select(reducers.account.getBalanceRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.account.GetBalance())),  // API call
        share(),
        startWith(null)  // required for muteFirst to work
      ),
      store.pipe(
        select(this._account),
        select(reducers.account.getBalance)
      )
    );

    this.accountName = muteFirst(
      store.pipe(
        select(this._account),
        select(reducers.account.getDetailsRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.account.GetAccount())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._account),
        select(reducers.account.getName)
      )
    );

    this.pflotranVersion = muteFirst(
      store.pipe(
        select(this._account),
        select(reducers.account.getDetailsRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.account.GetAccount())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._account),
        select(reducers.account.getPflotranVersion)
      )
    );

    this.accountTransactions = muteFirst(
      store.pipe(
        select(this._account),
        select(reducers.account.getTransactionsRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.account.GetTransactions())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._account),
        select(reducers.account.getTransactions)
      )
    );

    //
    // Compute
    //
    this.queue = muteFirst(
      store.pipe(
        select(this._compute),
        select(reducers.computeResources.getQueueRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.computeResources.GetQueue())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._compute),
        select(reducers.computeResources.getQueue)
      )
    );

    this.testMachineState = muteFirst(
      store.pipe(
        select(this._compute),
        select(reducers.computeResources.getTestMachineRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.computeResources.GetTestMachineState())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._compute),
        select(reducers.computeResources.getTestMachineState)
      )
    );

    //
    // Projects
    //
    this.projects = muteFirst(
      store.pipe(
        select(this._projects),
        select(reducers.projects.getRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.projects.Load())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._projects),
        select(reducers.projects.getProjects)
      )
    );

    // When a user selects a project, the UUID is stored first, rather than the full project
    // This approach means that a UUID can be selected before all the projects are loads
    // This is useful, for example, on pages that have a project UUID in the URI
    this.selectedProject = muteFirst(
      observableCombineLatest(
        this.projects,  // all projects
        store.pipe(
          select(this._projects),
          select(reducers.projects.getSelectedProjectUUID)  // uuid of the select project
        )
      ).pipe(
        // this map grabs the selected project from the list of all of them, else returns undefined
        map(
          ([projects, uuid]: [Project[], string]) =>
            !!uuid ?
              projects.find((project: Project) => project.uuid === uuid)
              :
              undefined
        ),
        tap((project: Project) => this.dispatch(new actions.projects.StoreSelected(project))),
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._projects),
        select(reducers.projects.getSelectedProject)
      )
    );

    //
    // Sub Projects
    //
    this.allSubProjects = muteFirst(
      store.pipe(
        select(this._subProjects),
        select(reducers.subProjects.getRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.subProjects.Load())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._subProjects),
        select(reducers.subProjects.getAllSubProjects)
      )
    );

    this.filteredSubProjects = muteFirst(
      observableCombineLatest(
        this.allSubProjects,
        this.selectedProject
      ).pipe(
        // filter the list of all subprojects using the UUID of the selected project.
        map(
          ([subProjects, project]: [SubProject[], Project]) =>
            !!project ?
              subProjects.filter((subProject: SubProject) => subProject.project === project.uuid)
              :
              [...subProjects]
        ),
        tap((subProjects: SubProject[]) => store.dispatch(new actions.subProjects.StoreFiltered(subProjects))),
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._subProjects),
        select(reducers.subProjects.getFilteredSubProjects)
      )
    );

    this.selectedSubProject = muteFirst(
      observableCombineLatest(
        this.filteredSubProjects,  // can only a select a subproject from the currently filtered list
        store.pipe(
          select(this._subProjects),
          select(reducers.subProjects.getSelectedSubProjectUUID)
        )
      ).pipe(
        // find the subproject in the list of filtered subprojects
        map(
          ([subProjects, uuid]: [SubProject[], string]) =>
            !!uuid ?
              subProjects.find((subProject: SubProject) => subProject.uuid === uuid)
              :
              undefined
        ),
        // subproject belongs to a project; make sure that project is selected also
        tap((subProject: SubProject) =>
          !!subProject ?
            store.dispatch(new actions.projects.Select({uuid: subProject.project}))
            :
            null
        ),
        tap((subProject: SubProject) => this.dispatch(new actions.subProjects.StoreSelected(subProject))),
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._subProjects),
        select(reducers.subProjects.getSelectedSubProject)
      )
    );

    this.plots1DExternalDataFiles = muteFirst(
      observableCombineLatest(
        store.pipe(
          select(this._plots),
          select(reducers.oneDPlots.getExternalDataFilesRefresh)
        ),
        this.selectedSubProject
      ).pipe(
        // get from backend is refresh = true or they is a new subproject
        filter(([refresh, subProject]: [boolean, SubProject]) => refresh && !!subProject),
        tap(([refresh, subProject]: [boolean, SubProject]) =>
          store.dispatch(new actions.oneDPlots.LoadExternalDataFiles(subProject.uuid))  // API call
        ),
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._plots),
        select(reducers.oneDPlots.getExternalDataFiles)
      )
    );

    //
    // Runs
    //
    this.allRuns = muteFirst(
      store.pipe(
        select(this._runs),
        select(reducers.runs.getRefresh),
        filter(refresh => !!refresh),
        tap(() => store.dispatch(new actions.runs.Load())),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._runs),
        select(reducers.runs.getAll)
      )
    );

    this.filteredRuns = muteFirst(
      observableCombineLatest(
        this.allRuns,
        this.selectedProject,
        this.selectedSubProject
      ).pipe(
        // filter by what is selected; preference order: subProject, project.
        map(([allRuns, project, subProject]: [Run[], Project, SubProject]) =>
          !!subProject ?
            allRuns.filter((run: Run) => subProject.uuid === run.sub_project) :
            !!project ?
              allRuns.filter((run: Run) => project.sub_projects.indexOf(run.sub_project) > -1) :
              [...allRuns]
        ),
        tap((runs: Run[]) => store.dispatch(new actions.runs.FilterSuccess(runs))),
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._runs),
        select(reducers.runs.getFiltered)
      )
    );

    this.selectedRun = muteFirst(
      observableCombineLatest(
        store.pipe(
          select(this._runs),
          select(reducers.runs.getSelectedUUID)
        ),
        store.pipe(
          select(this._runs),
          select(reducers.runs.getSelected)
        ),
        store.pipe(
          select(this._runs),
          select(reducers.runs.getRefreshSelected)
        )
      ).pipe(
        // Fetch new data is refresh = true or there is a select run already but the request uuid is not the same
        filter(([uuid, run, refresh]: [string, Run, boolean]) => refresh || (run && run.uuid !== uuid)),
        tap(([uuid, run, refresh]: [string, Run, boolean]) => {
          if (uuid) {
            store.dispatch(new actions.runs.LoadOneAndSelect(uuid));  // API call
          } else {
            store.dispatch(new actions.runs.SelectClear());  // Clear out the selected run
          }
        }),
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._runs),
        select(reducers.runs.getSelected)
      )
    );

    this.plots1DConfig = observableCombineLatest(
      store.pipe(
        select(this._plots),
        select(reducers.oneDPlots.getConfig)
      ),
      this.selectedRun
    ).pipe(
      map(([config, run]: [OneDPlotConfig, Run]) => {
        // get config from run if it exists, otherwise create a blank one.
        const runConfig: OneDPlotConfig =
          !!run && !!run.metadata && !!run.metadata.plots_1d && !!run.metadata.plots_1d.plots ?
            run.metadata.plots_1d
            :
            Object.assign({}, {plots: [], compare: []});

        // Take plots from run; however, where identical to current plots, use current plots.
        // This ensures removed or new plots from backend are accounted for but loading minimised
        const newPlots: Plot[] = runConfig.plots.map((p2: Plot) => {
          const p1: Plot = config.plots.find((p: Plot) => p.id === p2.id);
          return p1 && twoObjectsSame(p1, p2) ? p1 : p2;
        });
        if (!arraysEqual(config.plots, newPlots) || !arraysEqual(runConfig.compare, config.compare)) {
          store.dispatch(new actions.oneDPlots.StorePlotsConfig(
            Object.assign({}, {plots: newPlots, compare: runConfig.compare})
          ));
        }
        return config;
      })
    );

    // Short-cut to getting the plots from the above function
    this.plots1D = muteFirst(
      this.plots1DConfig,
      store.pipe(
        select(this._plots),
        select(reducers.oneDPlots.getConfig),
        map((config: OneDPlotConfig) => config.plots)
      )
    );

// Short-cut to getting the compare UUIDs from the above function
    this.plots1DCompareRuns = muteFirst(
      this.plots1DConfig,
      store.pipe(
        select(this._plots),
        select(reducers.oneDPlots.getConfig),
        map((config: OneDPlotConfig) => config.compare)
      )
    );

    //
    // Fluid Properties
    //
    this.fluidProps = muteFirst(
      observableCombineLatest(
        this.selectedSubProjectUUID,
        store.pipe(
          select(this._fluidProperties),
          select(reducers.fluidProps.getRefresh),
          filter(refresh => !!refresh)
        )
      ).pipe(
        filter(([subProject, refresh]: [string, boolean]) => !!subProject),  // need a subproject uuid stored!
        tap(([subProject, refresh]: [string, boolean]) => store.dispatch(new actions.fluidProps.Load(subProject))),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._fluidProperties),
        select(reducers.fluidProps.getFluidProperties)
      )
    );

    //
    // General Files Properties
    //
    this.generalFilesProps = muteFirst(
      observableCombineLatest(
        this.selectedSubProjectUUID,
        store.pipe(
          select(this._generalFilesProperties),
          select(reducers.generalFilesProps.getRefresh),
          filter(refresh => !!refresh)
        )
      ).pipe(
        filter(([subProject, refresh]: [string, boolean]) => !!subProject),  // need a subproject uuid stored!
        tap(([subProject, refresh]: [string, boolean]) => store.dispatch(new actions.generalFilesProps.Load(subProject))),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._generalFilesProperties),
        select(reducers.generalFilesProps.getGeneralFiles)
      )
    );

    //
    // Grid Files
    //
    this.grids = muteFirst(
      observableCombineLatest(
        this.selectedSubProjectUUID,
        store.pipe(
          select(this._grids),
          select(reducers.grids.getRefresh),
          filter(refresh => !!refresh),
        )
      ).pipe(
        filter(([subProject, refresh]: [string, boolean]) => !!subProject),  // need a subproject uuid stored!
        tap(([subProject, refresh]: [string, boolean]) => store.dispatch(new actions.grids.Load(subProject))),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._grids),
        select(reducers.grids.getAllGrids)
      )
    );

    //
    // Material Properties
    //
    this.materialProps = muteFirst(
      observableCombineLatest(
        this.selectedSubProjectUUID,
        store.pipe(
          select(this._materialProperties),
          select(reducers.materialProps.getRefresh),
          filter(refresh => !!refresh)
        )
      ).pipe(
        filter(([subProject, refresh]: [string, boolean]) => !!subProject),  // need a subproject uuid stored!
        tap(([subProject, refresh]: [string, boolean]) => store.dispatch(new actions.materialProps.Load(subProject))),  // API call
        share(),
        startWith(null)
      ),
      store.pipe(
        select(this._materialProperties),
        select(reducers.materialProps.getMaterialProperties)
      )
    );

    // End of constructor
  }

  // Expose store dispatch to components without them need to import/contruct a reference to the store
  // This simply saves on a bit of boiler plate, considering that most components will import this service anyway
  dispatch(action): void {
    this.store.dispatch(action);
  }

  getValueOnce(selection) {
    // Helper function to get the current value of something in the store.
    //
    // Note: this only works for ngrx store values, that are more like BehaviourSubjects than Observables
    // Using this function on a true Observable will return undefined as the Observable will not emit at the exact
    //     moment the function is called.
    let returnValue: any;
    selection
      .pipe(take(1))
      .subscribe(storeValue => returnValue = storeValue);
    return returnValue;
  }
}
