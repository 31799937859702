/* ==================================================================================================================
 * OpenGoSim Bluebell: app/store/reducers/projects.reducers.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import * as actions from '../actions';
import { Project } from '../interfaces';
import { CLEAR_ALL_STORES, REFRESH_ALL } from '../app.store.constants';

export interface State {
  loaded: boolean;
  loading: boolean;
  projects: Project[];
  refresh: boolean;
  selectedProject: Project;
  selectedUUID: string;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  projects: [],
  refresh: true,
  selectedProject: undefined,
  selectedUUID: undefined
};

export function reducer(state = initialState, action): State {
  switch (action.type) {

    case actions.projects.CREATE_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        projects: [...state.projects, action.payload]
      });
    }

    case actions.projects.DELETE_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        projects: state.projects.filter((prog: Project) => prog.uuid !== action.payload.uuid)
      });
    }

    case actions.projects.LOAD_PROJECTS_SET_LOADING: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case actions.projects.LOAD_PROJECTS_SUCCESS: {
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        projects: [...action.payload],
        refresh: false
      });
    }

    case actions.projects.SELECT_PROJECT:
      return Object.assign({}, state, {
        selectedUUID: action.payload ? action.payload.uuid : undefined
      });

    case actions.projects.STORE_SELECTED_PROJECT:
      return Object.assign({} , state, {
        selectedProject: action.payload
      });

    case actions.projects.UPDATE_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        projects: state.projects
          .map((prog: Project) => prog.uuid === action.payload.uuid ? action.payload : prog)
      });
    }

    case actions.projects.REFRESH_PROJECTS:
    case REFRESH_ALL:
      return Object.assign({}, state, {
        refresh: true
      });

    case CLEAR_ALL_STORES:
      return Object.assign({}, initialState);

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getProjects = (state: State) => state.projects;

export const getRefresh = (state: State) => state.refresh;

export const getSelectedProject = (state: State) => state.selectedProject;

export const getSelectedProjectUUID = (state: State) => state.selectedUUID;
